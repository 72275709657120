/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/react-hooks";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { GET_ARCHIVED_REPAIRS_FULL, GET_REPAIRS_FULL, GET_SINGLE_REPAIR } from "../graphql/queries";
import { useUserContext } from "./UserContext";

const defaultContextValue = {
  repairs: [],
  archivedRepairs: [],
  archivedLoading: false,
  loading: false,
  error: undefined,
  refetch: () => {},
  refetchArchived: (force: boolean = false) => {},
  refetchSingle: (repairId: string) => {},
};

export const RepairContext = createContext(defaultContextValue);

const RepairContextProvider = (props) => {
  const { called: userInitialized, isAdmin, userData } = useUserContext();
  const [callQuery, nonArchivedRes] = useLazyQuery(GET_REPAIRS_FULL, {
    fetchPolicy: "network-only",
    pollInterval: isAdmin ? 5000 : 60 * 1000 * 2,
  });

  const [callQueryArchived, archivedRes] = useLazyQuery(GET_ARCHIVED_REPAIRS_FULL, {
    fetchPolicy: "network-only",
    pollInterval: 60 * 1000 * 5,
  });

  const loading = nonArchivedRes.loading;
  const called = nonArchivedRes.called;
  const error = nonArchivedRes.error;

  const [refetchIds, setRefetchIds] = useState([]);
  const [getSingleRepair, singleRes] = useLazyQuery(GET_SINGLE_REPAIR, {
    fetchPolicy: "network-only",
  });

  const [repairs, setRepairs] = useState([]);
  const { children } = props;

  const refetch = (repairId: string) => {
    setRefetchIds((prev) => [...prev, repairId]);
  };

  useEffect(() => {
    const nextId = refetchIds[0];
    if (!nextId) return;
    if (singleRes.loading) return;
    if (!singleRes.data) {
      getSingleRepair({ variables: { id: nextId } });
      return;
    }
    const newRepair = singleRes.data.repair[0];
    const copy = JSON.parse(JSON.stringify(repairs)) as Array<any>;
    const index = copy.findIndex((repair) => repair.id === newRepair.id);
    setRefetchIds((prev) => prev.slice(1));
    if (index === -1) return;
    copy[index] = newRepair;
    setRepairs(copy);
  }, [singleRes, refetchIds]);

  useEffect(() => {
    if (!userInitialized) return;
    if (!called) {
      callQuery();
      return;
    }
    if (loading) return;
    setRepairs(nonArchivedRes.data.repair);
  }, [userInitialized, loading, called, nonArchivedRes]);

  const managedUserEmails = userData?.managedUsers.map((user) => user.email) || [];
  const actualManagedUserEmails: string[] =
    isAdmin || !managedUserEmails.length ? [] : [...managedUserEmails, userData?.email].filter(Boolean);
  const archivedRepairs = archivedRes.data?.repair || [];

  const filteredRepairs = useMemo(
    () => repairs.filter((repair) => (managedUserEmails.length ? managedUserEmails.includes(repair.user.email) : true)),
    [repairs, actualManagedUserEmails]
  );

  const filteredArchivedRepairs = useMemo(
    () =>
      archivedRepairs.filter((repair) =>
        managedUserEmails.length ? managedUserEmails.includes(repair.user.email) : true
      ),
    [archivedRepairs, actualManagedUserEmails]
  );

  const refetchArchived = (force: boolean = false) => {
    if (!force && (archivedRes.loading || archivedRes.called)) return;
    callQueryArchived();
  };

  return (
    <RepairContext.Provider
      value={{
        repairs: filteredRepairs,
        archivedRepairs: filteredArchivedRepairs,
        archivedLoading: archivedRes.loading,
        loading,
        error,
        refetch: callQuery,
        refetchSingle: refetch,
        refetchArchived,
      }}
    >
      {children}
    </RepairContext.Provider>
  );
};

export default RepairContextProvider;

export const useRepairContext = () => {
  const context = useContext(RepairContext);
  if (!context) throw new Error("RepairContext must be used with RepairContextProvider!");

  return context;
};
