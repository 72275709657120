import gql from "graphql-tag";

const RepairDetails = gql`
  fragment RepairDetails on repair {
    __typename
    id
    repairNumber
    status
    shippingLabel
    trackingCode
    returnLabel
    returnTrackingCode
    returnDate
    invoiceURL
    discountCode
    sentTo
    createdAt
    isReclamation
    reclamationRepairNumber
    note
    additionalInfo
    repairPieces {
      __typename
      id
      damageCategories
      damageDescription
      manufacturer
      model
      productType
      serialNumber
    }
    user {
      __typename
      id
      customerNumber
      email
      firstName
      lastName
      office
      street
      zip
      city
      country
      phone
      isColosseum
      isDesos
    }
    costEstimates(order_by: { createdAt: asc }) {
      __typename
      id
      costEstimateNumber
      currency
      taxRate
      discountFlat
      discountPercent
      approvedAt
      createdAt
      lineItems {
        __typename
        id
        articleNumber
        headline
        description
        price
        accepted
        mandatory
      }
    }
    invoice {
      id
      invoiceNumber
      paid
    }
  }
`;

export const GET_REPAIRS_FULL = gql`
  query GET_REPAIRS_FULL {
    repair(
      order_by: { createdAt: desc }
      where: { deleted: { _eq: false }, status: { _nin: ["finished", "irrepairable", "rejected"] } }
    ) {
      ...RepairDetails
    }
  }
  ${RepairDetails}
`;

export const GET_ARCHIVED_REPAIRS_FULL = gql`
  query GET_REPAIRS_FULL {
    repair(
      order_by: { createdAt: desc }
      where: {
        deleted: { _eq: false }
        status: { _in: ["finished", "irrepairable", "rejected"] }
        createdAt: { _gte: "2023-01-01T00:00:00.977Z" }
      }
    ) {
      ...RepairDetails
    }
  }
  ${RepairDetails}
`;

export const GET_SINGLE_REPAIR = gql`
  query GET_SINGLE_REPAIR($id: uuid!) {
    repair(where: { deleted: { _eq: false }, id: { _eq: $id } }) {
      ...RepairDetails
    }
  }
  ${RepairDetails}
`;

export const CREATE_LABEL = gql`
  mutation MyMutation($input: CreateUPSLabelInput!) {
    createUPSLabel(input: $input) {
      label
      trackingCode
    }
  }
`;
export const GET_EMAIL_TEMPLATES = gql`
  query GET_EMAIL_TEMPLATES {
    emailTemplates {
      __typename
      label
      subject
      body
    }
  }
`;

export const UPDATE_REPAIR = gql`
  mutation UPDATE_REPAIR($id: uuid!, $set: repair_set_input!) {
    update_repair_by_pk(pk_columns: { id: $id }, _set: $set) {
      __typename
      id
      returnLabel
    }
  }
`;

export const INSERT_COST_ESTIMATE = gql`
  mutation INSERT_COST_ESTIMATE(
    $repairId: uuid
    $discountFlat: Int
    $discountPercent: Int
    $currency: String
    $taxRate: float8
    $lineItems: [line_item_insert_input!]!
  ) {
    insert_costEstimate(
      objects: {
        repairId: $repairId
        discountFlat: $discountFlat
        discountPercent: $discountPercent
        currency: $currency
        taxRate: $taxRate
        lineItems: { data: $lineItems }
      }
    ) {
      returning {
        id
        approvedAt
      }
    }
  }
`;
export const SET_ESTIMATE_APPROVAL = gql`
  mutation SET_ESTIMATE_APPROVAL($id: uuid!, $approvedAt: timestamptz) {
    update_costEstimate(where: { id: { _eq: $id } }, _set: { approvedAt: $approvedAt }) {
      returning {
        id
      }
    }
  }
`;

export const SET_LINEITEM_ACCEPTED = gql`
  mutation SET_LINEITEM_ACCEPTED($id: uuid!, $accepted: Boolean) {
    update_lineItem(where: { id: { _eq: $id } }, _set: { accepted: $accepted }) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_INVOICE = gql`
  mutation CREATE_INVOICE($costEstimateId: uuid!, $repairId: uuid!) {
    insert_invoice(objects: { costEstimateId: $costEstimateId, repairId: $repairId }) {
      returning {
        id
      }
    }
  }
`;
